import {
  WrapPage,
  Container,
  H1,
  P,
  VerticalLine,
  GoHomeBtn,
} from '@styles/common/error.styles';
import Head from 'next/head';
import React from 'react';

export default function Custom404() {
  return (
    <>
      <Head>
        <title>Error 404 - Page not found</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1"
        />
        <meta name="description" content="Socializing is the way" />
        <link
          rel="icon"
          href="https://res.cloudinary.com/dpnbddror/image/upload/w_1000,c_fill,ar_1:1,g_auto,r_max,bo_5px_solid_red,b_rgb:262c35/v1637870721/brand_assets/LOGO_Karent_tx5hn5.png"
        />
      </Head>
      <WrapPage>
        <Container>
          <H1>404</H1>
          <VerticalLine />
          <P>Page not found 😕</P>
        </Container>
        <GoHomeBtn
          className="rounded-3xl bg-white px-6 py-2 font-semibold text-gray-800"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          Go Home
        </GoHomeBtn>
      </WrapPage>
    </>
  );
}
